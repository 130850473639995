<template>
  <v-container>
    <v-row>
      <v-col
        :key="i._id"
        v-for="i in printParcel.parcelDetails"
        cols="12"
        md="4"
      >
        <v-card class="pa-5" max-width="400">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <v-avatar size="36px">
                  <img alt="Avatar" :src="require('../assets/icon.png')" />
                </v-avatar>
                Golegit
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getUser.fulfillment }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="text-center mt-4 font-weight-thin">www.Golegit.ng</div>
          <div class="text-center mb-4 font-weight-thin">
            <small>{{ Time(i.created) }}</small>
          </div>
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Tracking No.</v-list-item-title
            >
            <div class="font-weight-thin">{{ i.trackingNumber }}</div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Pickup Address.</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ printParcel.pickUpLocation.Street }}
            </div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Delivery Address.</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ i.Street }}
            </div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Distance</v-list-item-title
            >
            <div class="font-weight-thin">{{ i.distanceKm }}</div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title
              class="font-weight-normal"
              v-if="i.deliveryMethod"
            >
              Delivery Method
            </v-list-item-title>
            <div class="font-weight-thin">{{ i.deliveryMethod }}</div>
          </div>

          <v-divider v-if="i.deliveryMethod" />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Amount</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ DisplayCurrency(i.shippingFee) }}
            </div>
          </div>
          <div class="text-center">
            <Print :printParcel="printParcel" :item="i" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
import Print from "../components/Print.vue";
export default {
  components: {
    Print,
  },
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  methods: {
    Time(payload) {
      return dayjs(payload).format("ddd, MMM DD, YYYY,");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
  },
  computed: {
    printParcel() {
      return this.$store.getters.printParcel;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<style scoped>
.listDiv {
  margin-top: 5px;
}
</style>