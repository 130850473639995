<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Print
        </v-btn>
      </template>

      <v-card class="pa-5">
        <div class="pa-5" id="printMe">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <v-avatar size="36px">
                  <img alt="Avatar" :src="require('../assets/icon.png')" />
                </v-avatar>
                Golegit
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getUser.fulfillment }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="text-center mt-4 font-weight-thin">www.Golegit.ng</div>
          <div class="text-center mb-4 font-weight-thin">
            <small>{{ Time(item.created) }}</small>
          </div>
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Tracking No.</v-list-item-title
            >
            <div class="font-weight-thin">{{ item.trackingNumber }}</div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Pickup Address.</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ printParcel.pickUpLocation.Street }}
            </div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Delivery Address.</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ item.Street }}
            </div>
          </div>
          <v-divider />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Distance</v-list-item-title
            >
            <div class="font-weight-thin">{{ item.distanceKm }}</div>
          </div>
          <v-divider />
          <div class="listDiv" v-if="item.deliveryMethod">
            <v-list-item-title class="font-weight-normal"
              >Delivery Method</v-list-item-title
            >
            <div class="font-weight-thin">{{ item.deliveryMethod }}</div>
          </div>

          <v-divider v-if="item.deliveryMethod" />
          <div class="listDiv">
            <v-list-item-title class="font-weight-normal"
              >Amount</v-list-item-title
            >
            <div class="font-weight-thin">
              {{ DisplayCurrency(item.shippingFee) }}
            </div>
          </div>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="print" @click="print"> Print </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
export default {
  props: ["item", "printParcel"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
    Time(payload) {
      return dayjs(payload).format("ddd, MMM DD, YYYY,");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
  },
};
</script>